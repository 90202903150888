import { TokenProvider } from "stream-chat";
import * as CSRF from "./csrf";

// The Stream Client can use an async function called a TokenProvider to fetch a
// user's access token. Developers are expected to build their own TokenProvider
// according to their own authentication strategy.
//
// If the page provides a static user access token (e.g. from the
// data-access-token attr), we can use that. No need for a separate request to
// refresh. Ideal in standalone mode where the token never expires.
//
// That said, if the token expires, the Stream Client will raise an internal
// error and it will call the TokenProvider again. We'll need to switch to the
// remote provider to have the server generate a fresh token.
export default class TokenProviderFactory {
  providers: Array<TokenProvider>;

  constructor(refreshTokenUrl?: string, initialAccessToken?: string) {

    // At first, use the constant provider to use the initial access token
    // provided on the page. If we ever need to refresh the token, we'll switch to
    // the remote provider.
    this.providers = [];

    if(refreshTokenUrl && refreshTokenUrl.length > 0) {
      this.providers.push(remoteProvider(refreshTokenUrl));
    }

    if(initialAccessToken && initialAccessToken.length > 0) {
      this.providers.push(constantProvider(initialAccessToken));
    }

    if(this.providers.length < 1) {
      throw new Error("At least one of refreshTokenUrl or initialAccessToken is required");
    }
  }

  get current(): TokenProvider {
    const current = this.providers[this.providers.length - 1];

    // Always swap to the url provider after the first call. We only want to use
    // the initial token once. If it's still valid, this function will never be
    // called again. If it expires while the page is loaded, the StreamClient
    // will call this function again and we'll need to fetch a fresh token from
    // the server.
    if(this.providers.length > 1) {
      this.providers.pop();
    }

    return current;
  }

  build(): TokenProvider {
    return async () => {
      const provider = this.current;
      return await provider();
    };
  }
}

// This provider always returns the same value.
function constantProvider(token: string): TokenProvider {
  return async () => { return token; };
}

// This provider fetches the token from a remote URL.
//
// Expects the token to be in a json payload in the shape:
//    `{data: {access_token: "value"}}`
function remoteProvider(url: string): TokenProvider {
  const headers = buildHeaders();

  return async () => {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
    });

    const body = await response.json();

    if(response.ok) {
      return body.data.access_token;
    } else {
      throw new Error(`Failed to fetch new user access token: ${response.statusText}`);
    }
  };
};

interface HttpHeaders {
  [index: string]: string;

  'Content-Type': string;
}

function buildHeaders(): HttpHeaders {
  const headers: HttpHeaders = {
    'Content-Type': 'application/json',
  };

  const token = CSRF.token();
  if (token && token.length > 0) {
    headers['X-CSRF-TOKEN'] = token;
  };

  return headers;
}
