import * as EmojiMart from 'emoji-mart';
import EmojiMartData from '@emoji-mart/data';
import { ChannelFilters, User } from 'stream-chat';
import { Chat, Channel, ChannelHeader, ChannelList, MessageInput, MessageList, Thread, Window, useCreateChatClient } from 'stream-chat-react';
import { EmojiPicker } from 'stream-chat-react/emojis';
import { useMemo } from 'react';

import './layout.css';
import TokenProviderFactory from './token_provider_factory';

EmojiMart.init({EmojiMartData});

export interface AppProps {
  accessToken?: string;
  apiKey: string;
  appId: string;
  refreshTokenUrl?: string;
  userId: string;
  userName: string;
}

const App = ({accessToken, apiKey, refreshTokenUrl, userId, userName}: AppProps): JSX.Element => {
  const tokenProvider = useMemo(
    () => new TokenProviderFactory(refreshTokenUrl, accessToken).build(),
    [refreshTokenUrl, accessToken]
  );

  const user: User = {
    id: userId,
    name: userName,
    image: `https://getstream.io/random_png/?name=${userName}`,
  };

  const filters: ChannelFilters = {
    members: { $in: [userId] },
  };

  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: tokenProvider,
    userData: user,
  });

  if (!client) return <div>Setting up client & connection...</div>;

  return (
    <Chat client={client}>
      <ChannelList filters={filters} sort={{ last_message_at: -1 }} />
      <Channel EmojiPicker={EmojiPicker} emojiSearchIndex={EmojiMart.SearchIndex}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default App;
